import React from "react";
import { portfolioItems } from "../data/portfolioItems"
import PortfolioItem from "./portfolioItem";



function Portfolio() {
  const showPortfolioItems = portfolioItems.map((item)=>{
    return(
        <PortfolioItem key={item.id} item={item}/>
    )
    })
  
  return (
    <div id="our-work" className="relative bg-white pb-16 sm:pb-24 lg:pb-32 pt-8 sm:pt-12 lg:pt-16">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Some of our  <span className="italic text-4xl sm:text-5xl"></span><br/><span className="text-skblue italic text-4xl sm:text-5xl">Proud Work</span>
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
            {showPortfolioItems}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
