import PropTypes from "prop-types";
import React from "react";



function PortfolioItem({ item }) {
    
  return (
    <div className="flex flex-col h-full rounded-lg shadow-lg">
    <a
        href={item.url}
        target="_blank"
        rel="noreferrer"
    >
      <div className="">
          <img className="h-72 w-full object-cover" src={item.image} alt={item.title}/>
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <div className="mt-2">
            <p className="text-xl font-semibold text-gray-900">
              {item.title}
            </p>
            <p className="mt-3 text-base text-gray-500">
              {item.description}
            </p>
          </div>
        </div>
      </div>
      </a>
    </div>
  );
}

PortfolioItem.propTypes = {
  item: PropTypes.any,
};

export default PortfolioItem;
