import React from "react";
import SpeedMascot from "../images/speed-mascot.svg";
import SecureMascot from "../images/secure-mascot.svg";
import SEOMascot from "../images/seo-mascot.svg";
import MobileMascot from "../images/mobile-mascot.svg";
import DesignMascot from "../images/design-mascot.svg";
import CMSMascot from "../images/cms-mascot.svg";


const features = [
  {
    name: "The Need for Speed Has Been Achieved",
    description:
      `We’re legit talking <em>superhero fast</em>. Forget about annoying little icons that go around and around trying to hypnotize the visitor into thinking it’s not taking so long to load. Wrong. It is taking <a>way</a> too long. At SkilledWebsites, we use cutting edge design and coding to ensure your site is outrageously responsive and fast on every device. No more boring load times. No more waiting. You’ll look awesome and your visitors won’t want to fall asleep while they're trying to give you business. - guaranteed!
      `,
    src: SpeedMascot,
    span: ''
  },
  {
    name: "Search Engine Optimized",
    description:
      `Imagine the poor websites that are linked on page two of Google. Sad. Lonely. No one sees them and they live a life of quiet desperation. “Please, someone click on me...<em>please!</em>” Sorry little website dude, not going to happen. If your website doesn’t make it to page one of the search engine, does it even exist? Not really. That’s why we build your website with search friendly optimization at the forefront of our design. S.E.O. is basically all the boring, behind the scenes stuff that gets your website noticed. We handle it, and don’t brag about it - but it gets done the right way. You could say we’re kind of like a superhero with SEO emblazoned on our cape. We’ll make your site fly, zoom and WOW - it’s just what we do.
      `,
    src: SEOMascot,
    span: ''
  },
  {
    name: "Secure",
    description:
      `Having your website hacked can feel like a super villain crept into your business and started leaving boobytraps everywhere. Not good, not fun. Our concept of a “Secure Website” is one that is pretty much <em>unhackable</em>. Your business website is too important to leave the front door open with a sign that says “Welcome, Intruders! Take What You Want!” Instead, we ensure the highest level of security in every layer of website design. Super Villains are a thing of the past - and your new website is basically kryptonite to keep it that way.
      `,
    src: SecureMascot,
    span: ''   
  },
  {
    name: "Mobile Optimized",
    description:
      `Turns out people don’t walk around with a desktop computer strapped to their backs. These days there are a million different devices that can access the internet. Connected devices like iPhones, Android Phones, iPads, Amazon Fire tablets and on and on. The good news is that we ensure each and every website will look and function flawlessly on every single device. Yes, even <em>that one</em>. Our super strength is in making your online presence be the belle of the ball no matter where people see it. So good looking, you might even want to ask it to prom. OK - maybe not that good looking, but awesome for sure.
      `,
    src: MobileMascot,
    span: ''
  },
  {
    name: "CMS Integration",
    description:
      `Managing content can be annoying, clumsy and time consuming. That’s why we took those three words, wrote them on a piece of paper and burned them in a ceremonial event we called, “STUFF WE’LL NEVER LET HAPPEN.” Our sites seamlessly integrate content with ease. No technical knowledge needed, no weird coding style that hasn’t been used since 1998. Our modern infrastructure makes producing content on your websites fast, effective and efficient. Frankly, it’s so simple, you may even want to produce <em>more</em> content because of it. Bonus: maybe we’ll invite you to our next ceremonial event we’re planning called <em>“Amazing Content Produced By Our Amazing Clients,”</em> you deserve it.`,
    src: CMSMascot,
    span: ''
  },
  {
    name: "Beautiful Design",
    description:
      `You know how every superhero has a very attractive counterpart? We’ll make your website just that. Perfectly laid out, attractive user experience and unmatched performance. Consider us the high end, premium salon of websites. We don’t just give makeovers, we design from the ground up so that everything functions perfectly and looks amazing. Some heroes need to go into a phone booth and change into their costume - our websites <em>only</em> wear their best and brightest so every visitor on every device stops, stares and thinks, “Snap - now <em>this</em> is a website that looks amazing.”
      `,
    src: DesignMascot,
    span: ''
  },
];

function Feature() {
  return (
    <div id="what-we-do" className="relative bg-white pb-16 sm:pb-24 lg:pb-32 pt-8 sm:pt-12 lg:pt-16">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          Websites that make  <span className="italic text-4xl sm:text-5xl">you</span><br/>a <span className="text-skblue italic text-4xl sm:text-5xl">Digital Superhero</span>
        </p>
        <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
          All our websites come with an amazing skill set out of the box to help launch your online presence into the stratosphere. Some call it a “Website,” We prefer “Business Launchpad” &#x1F680;
        </p>
        <div className="mt-12">
          <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-2">
            {features.map((feature) => (
              <div key={feature.name} className="pt-6">
                <div className="flow-root bg-gray-50 h-full rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3">
                        <img
                          className="h-60 w-auto text-white"
                          aria-hidden="true"
                          src={feature.src}
                        />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      {feature.name}
                    </h3>
                    <p className="mt-5 text-base text-left text-gray-500" dangerouslySetInnerHTML={{ __html: feature.description}}>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Feature;
