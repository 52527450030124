import React from "react";

function About() {
  return (
    <div id="about-us" className="relative bg-white py-16 sm:py-24 lg:py-32">
      <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
        <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
          We’ve Got Skills - So, Let’s Rock and Roll &#x1F3B8;
        </p>
        <p className="mt-5 max-w-prose mx-auto text-left text-xl text-gray-500">
          ...and by “Rock and Roll,” we of course mean “Build You a Website.” Though honestly, we look at our services more like writing a hit song for our clients. We want it to be professional, thoughtful, give a good first impression and instantly be memorable - with the benefit that we don’t have to do the work from a tour bus.
        </p>
        <p className="mt-5 max-w-prose mx-auto text-left text-xl text-gray-500">
          Finding the right website provider can be a harrowing experience. Everybody tells you they can do better, everybody tries to get your business. We realized the best way to stand-out in a crowded market is to be the best. High standards, premium services, reasonable prices, absurdly good customer service.
        </p>
        <p className="mt-5 max-w-prose mx-auto text-left text-xl text-gray-500">
          We focus our skills on making your website amazing, from start to finish. No droning on about tech (though we’re more than happy to if you’re into that), no complicated workflows. Our job is to make your website dynamic, attractive and effective.
        </p>
        <p className="mt-5 max-w-prose mx-auto text-left text-xl text-gray-500">
          Our superhero power is making amazing websites. Let us help you bring your businesses superpower to the web and create something amazing.
        </p>
      </div>
    </div>
  );
}

export default About;
