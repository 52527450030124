import React from "react";
//import { useSpring, animated, config } from "react-spring"

function HeroMascot() {
  // const styles = useSpring({
  //   config: config.stiff,
  //   delay: 250,
  //   from: { opacity: 0, x: -1000 },
  //   to: {
  //       opacity: 1, x: 0
  //   }
  // })

  return (
    <h1  className="mt-4 text-4xl font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
        <span className="block">Websites</span>
        <span className="block">with</span>
        <span className="pb-3 text-7xl block text-skblue sm:pb-5 xl:text-8xl italic">
        Skills
        </span>
    </h1>         
  );
}

export default HeroMascot;
