import React from "react";

function Thankyou() {
  return (
      <div className="pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div className="relative max-w-7xl mx-auto">
          <div className="mx-auto">
            <h2 className="prose text-2xl text-center tracking-tight font-extrabold text-warm-gray-900">
              Thanks for reaching out!
              <br/>Someone will contact you soon. &#x1F44D; 
            </h2>
          </div>
        </div>
      </div>
  );
}

export default Thankyou;
