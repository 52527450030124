export const portfolioItems = [
    {
        "id": 1,
        "title": "Athletic Performance Establishment",
        "description": "",
        "image": "https://res.cloudinary.com/dy3c6sc72/image/upload/v1664411399/Skilled/Athletic-Performance-Establishment_hx8jks.png",
        "url": "https://www.apestablishment.com/",
        "github": "#",
        "techStack": ["WordPress"]
    },
    {
        "id": 2,
        "title": "Coldstream Mechanical",
        "description": "North Okanagan‘s premier plumbing, heating, and air conditioning company.",
        "image": "https://res.cloudinary.com/dy3c6sc72/image/upload/v1624421021/Skilled/Coldstream-Mechanical-We-are-Vernon-and-North-Okanagan_s-premier-plumbing-heating-and-air-conditioning-company-Coldstream-Mechanical_mommfw.png",
        "url": "https://www.coldstreammech.com/",
        "github": "#",
        "techStack": ["gatsby"]
    },
    {
        "id": 3,
        "title": "Okanagan Aquatech",
        "description": "Okanagan Aquatech Water Purification Solutions",
        "image": "https://res.cloudinary.com/dy3c6sc72/image/upload/v1621901366/portfolio/Okanagan-Aquatech-Inc-Vernon-and-the-Okanagan-s-1-choice-in-Reverse-Osmosis-Water-Softeners-UV-Purification-Filters-Flood-Prevention-Okanagan-Aquatech-LTD_rwej1s.png",
        "url": "https://www.okanaganaquatech.com/",
        "github": "#",
        "techStack": ["gatsby"]
    },
    {
        "id": 4,
        "title": "Sheople",
        "description": "",
        "image": "https://res.cloudinary.com/dy3c6sc72/image/upload/v1647572146/Skilled/Home_-_sheople_wkbxwn.png",
        "url": "https://sheople.io/",
        "github": "#",
        "techStack": ["React"]
    },
    {
        "id": 5,
        "title": "Stevenson Mechanical",
        "description": "Vernon Expert Plumbing and Heating Specialists",
        "image": "https://res.cloudinary.com/dy3c6sc72/image/upload/v1612161908/portfolio/stevensonmechanical_svchw2.png",
        "url": "https://www.stevensonmechanical.ca/",
        "github": "#",
        "techStack": ["html5","css3"]
    },
    {
        "id": 6,
        "title": "Nocean",
        "description": "",
        "image": "https://res.cloudinary.com/dy3c6sc72/image/upload/v1612161905/portfolio/nocean_uuzjjr.png",
        "url": "https://www.noceanwaves.com/",
        "github": "#",
        "techStack": ["react"]
    },
    {
        "id": 7,
        "title": "Seaton Contracting",
        "description": "",
        "image": "https://res.cloudinary.com/dy3c6sc72/image/upload/v1647572147/Skilled/Home_-_Seaton_Contracting_eua5w9.png",
        "url": "https://www.seatoncontracting.com/",
        "github": "#",
        "techStack": ["WordPress"]
    },
    {
        "id": 8,
        "title": "Heritage Stone Paving and Landscaping",
        "description": "",
        "image": "https://res.cloudinary.com/dy3c6sc72/image/upload/v1647572147/Skilled/Home_-_Heritage_Stone_Paving_and_Landscaping_qjrxvz.png",
        "url": "https://heritagestonepaving.com/",
        "github": "#",
        "techStack": ["WordPress"]
    },
]