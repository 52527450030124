import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Feature from "../components/feature";
import About from "../components/about";
import Portfolio from "../components/portfolio";
import Contact from "../components/contact";
import SEO from "../components/seo";

function IndexPage() {
  return (
    <Layout>
      <SEO keywords={[
          `Fast Websites`,
          `SEO`,
          `Mobile Optimized`,
          `Secure`,
          `CMS Integration`,
          `Beautiful Design`
        ]}
        title="Skilled Websites - Vernon's choice for High Quality Websites, Within Your Budget, SEO, Mobile Optimized"
        description="We focus our skills on making your website amazing, from start to finish. No droning on about tech (though we’re more than happy to if you’re into that), no complicated workflows. Our job is to make your website dynamic, attractive and effective." />
      <Hero />
      <Feature />
      <About />
      <Portfolio />
      <Contact />
    </Layout>
  );
}

export default IndexPage;
