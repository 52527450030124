import React from "react";
//import { useSpring, animated, config } from "react-spring"
import mascot from "../images/mascot.svg"

function HeroTextOne() {
  // const styles = useSpring({
  //   config: config.stiff,
  //   from: { opacity: 0, x: 5000 },
  //   to: {
  //       opacity: 1, x: 0
  //   }
  // })

  return (
    <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
        <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
          <img
            className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
            src={mascot}
            alt="Skilled Mascot"
          />
        </div>
    </div>         
  );
}

export default HeroTextOne;
